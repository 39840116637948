$(function() {


	// --------------------------------------------------------------------------
	// Header Sticky
	// --------------------------------------------------------------------------

	function stickyHeader() {

		var scrolling = $(window).scrollTop() || window.pageYOffset;

		if (scrolling > 0 ) {
			$('html').addClass('is-sticky');
		}
		else {
			$('html').removeClass('is-sticky');
		}

	}

	stickyHeader();

	$(window).on('scroll', function(event) {
		stickyHeader();
	});


	// --------------------------------------------------------------------------
	// Select
	// --------------------------------------------------------------------------

	$('select').selectric({
        maxHeight: 'auto',
        keySearchTimeout: 500,
        arrowButtonMarkup: '<span class="arrow"><svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.28088 7.55561C8.72904 7.55561 9.10624 8.35676 8.16661 9.3662L8.06089 9.47512L6.43907 11.0806C5.40434 12.1048 3.77465 12.1395 2.69808 11.2076L2.56089 11.0806L0.939075 9.47512C-0.103413 8.44316 0.194383 7.61454 1.56782 7.55862L1.71908 7.55561H7.28088ZM6.43907 0.919567L8.06089 2.525C9.13182 3.58511 8.78076 4.4445 7.28088 4.4445H1.71908C0.217364 4.4445 -0.140645 3.59381 0.939075 2.525L2.56089 0.919567C3.63182 -0.140548 5.35936 -0.149246 6.43907 0.919567Z" fill="#969696"/></svg></span>',
        disableOnMobile: false,
        nativeOnMobile: false,
        openOnHover: false,
        hoverIntentTimeout: 500,
        expandToItemText: false,
        responsive: true,
        customClass: {
            prefix: 'selectric',
            camelCase: false
        },
        optionsItemBuilder: '{text}',
        labelBuilder: '{text}',
        preventWindowScroll: false,
        inheritOriginalWidth: false,
        allowWrap: true,
        multiple: {
            separator: ', ',
            keepMenuOpen: true,
            maxLabelEntries: false
        },
        optionsItemBuilder: function(itemData, element, index) {
        	return itemData.text;
        },
        labelBuilder: function(itemData, index) {
        	return itemData.text;
        },
        onBeforeInit: function() {

        },
        onInit: function(itemData, element, index) {

        },
        onBeforeOpen: function(element) {

        },
        onOpen: function(element) {
        	
        },
        onBeforeClose: function() {
        	
        },
        onClose: function(element) {
        	
        },
        onBeforeChange: function() {

        },
        onChange: function(element) {
   
        },
        onRefresh: function() {

        },
	});
	
	// --------------------------------------------------------------------------
	// Fancybox
	// --------------------------------------------------------------------------

	var fancyboxOptions = {
		infobar : true,
		toolbar : true,
		clickOutside: true,
		touch: false,
		transitionEffect : 'slide',
		lang: 'ru',
		smallBtn: false,
		closeExisting: false,
		hideScrollbar: true,
		preventCaptionOverlap: true,
		autoFocus: false,
		buttons: [],
		btnTpl: {
		},
		i18n: {
			ru: {
				ERROR: "Запрашиваемый контент не может быть загружен. <br/> Пожалуйста, попробуйте позже.",
			}
		},
		beforeShow: function( instance, current ) {

			var compensateCSS = $('.compensate-for-scrollbar').css('margin-right');

	        $('.header').css({
	            'margin-right': compensateCSS
	        });

			// If #popup-tariff

			if ( $(current.opts.$orig).data('fancybox-tariff') ) {

				var tariffIndex = $(current.opts.$orig).data('fancybox-tariff');

				var $popup = $(current.src),
					$popupTitle  = $popup.find('.popup__tariff-title'),
					$popupPrice  = $popup.find('.popup__tariff-price'),
					$popupPeriod = $popup.find('.popup__tariff-period'),
					$popupInfo   = $popup.find('.popup__tariff-info');


				var $table = $('.tariffs__table'),
					$tableTitle  = $table.find('thead tr td').eq(tariffIndex).find('.tariffs__table-title').text(),
					$tablePrice  = $table.find('thead tr td').eq(tariffIndex).find('.tariffs__table-price').text(),
					$tablePeriod = $table.find('thead tr td').eq(tariffIndex).find('.tariffs__table-period').text(),
					$tableInfo   = $table.find('tbody tr');

				// Render
				$popupTitle.text($tableTitle);
				$popupPrice.text($tablePrice);
				$popupPeriod.text($tablePeriod);

				$popupInfo.empty();

				$tableInfo.each(function (index, value) {

					var $table_tr = $(this);

					var $table_tr_dt = $table_tr.find('td').eq(0).find('.tariffs__table-info').html();
					var $table_tr_dd = $table_tr.find('td').eq(tariffIndex).html();

					$popupInfo.append('<li>\
									  		<dl class="popup__tariff-dl">\
											  	<dt>'+ $table_tr_dt +'</dt>\
												<dd>'+ $table_tr_dd +'</dd>\
											</dl>\
									  </li>');
					
				});

			}

	    },
	    afterClose: function( instance, current ) {

	    	$('.header').css({
	            'margin-right': 0
	        });

	    }
	}

    $('[data-fancybox]').fancybox(fancyboxOptions);


	// --------------------------------------------------------------------------
	// Mask
	// --------------------------------------------------------------------------

	$('input[type="tel"]').mask('+7 (000) 000-00-00');

	$('input[type="tel"]').on('focus', function(){
		if ( $(this).val() === '' ) {
			$(this).val('+7 (');
		}
	});

	// --------------------------------------------------------------------------
	// Validate
	// --------------------------------------------------------------------------

	$.validator.addMethod("regexpTel", function (value, element) {
	    return this.optional(element) || /^\+\d \(\d{3}\) \d{3}-\d{2}-\d{2}$/.test(value);
	});

	var validateErrorPlacement = function(error, element) {
		error.addClass('ui-validate');
		error.appendTo(element.parent());
	}

	var validateHighlight = function(element) {
		$(element)
			.parent().addClass("is-error").removeClass('is-success').find('.ui-validate').remove();
	}
	var validateUnhighlight = function(element) {
		$(element)
			.parent().addClass('is-success').removeClass("is-error").find('.ui-validate').remove();
	}

	$('.js-validate').each(function (index, value) { 

		$(this).validate({
			errorElement: "span",
			ignore: ":disabled,:hidden",
			onkeyup: false,
			onfocusout: false,
			highlight: validateHighlight,
    		unhighlight: validateUnhighlight,
			rules: {
				fullname: {
	                required: true
				},
				firstname: {
	                required: true
				},
				secondname: {
	                required: true
				},
				middlename: {
	                required: true
				},
				tel: {
	                required: true,
	                regexpTel: true
				},
				email: {
					required: true,
      				email: true
				},
				comments: {
	                required: true
				},
				agree: {
	                required: true
				},
				rules: {
	                required: true
				},

			},
			messages: {
				fullname: 'Вы не ввели ФИО',
				firstname: 'Вы не ввели имя',
				secondname: 'Вы не ввели фамилию',
				middlename: 'Вы не ввели отчество',
				tel: 'Вы не ввели номер телефона',
				email: 'Вы не ввели email',
				comments: 'Вы не ввели сообщение',
				agree: 'Обязательное поле',
				rules: 'Обязательное поле',
			},
			errorPlacement: validateErrorPlacement,
			submitHandler: function(form) {



			}
		});

	});


	// --------------------------------------------------------------------------
	// Scroll To Id
	// --------------------------------------------------------------------------

	$('.js-scrollto').on('click', function(event) {
		event.preventDefault();

		var href = $(this).attr('href'),
			offsetTop = $(href).offset().top - 1;
		

	    $('html, body').animate({
	        scrollTop: offsetTop
	    }, 800);

	});

	// --------------------------------------------------------------------------
	// Loaded
	// --------------------------------------------------------------------------


	$('html').addClass('is-loaded');

});


